<template>
  <div class="clock-in-out">
    <div class="viewEdit">
      <div class="sticky-header">
        <b-card class="mb-0 timesheet-header">
          <!--view edit header -->
          <div class="card-header-edit">
            <p class="title">
              Clock Timesheet
            </p>
            <div class="card-header-second mt-0">
              <div class="calendar mt-0">
                <custom-calender
                  :start="startDate"
                  :end="endDate"
                  :active-btn="activeBtn"
                  :date-range-config="tab=='weekly'?dateRangeConfig:dateDayRangeConfig"
                  :from="'weekly'"
                  @date="updateDate"
                />
              </div>
              <div class="button-group">
                <b-button-group>
                  <b-button
                    :class="[
                      tab == 'daily'
                        ? 'button-group-item active'
                        : 'button-group-item',
                    ]"
                    @click="tab = 'daily'"
                  >Daily</b-button>
                  <b-button
                    :class="[
                      tab == 'weekly'
                        ? 'button-group-item-weekly active'
                        : 'button-group-item-weekly',
                    ]"
                    @click="tab = 'weekly'"
                  >Weekly</b-button>
                </b-button-group>
                <Help
                  v-show="$store.state.app.userManualList"
                  code="ClockTimesheet"
                />
              </div>
            </div>
            <button class="add-time d-none">
              Add time
            </button>
          </div>
        </b-card>
        <div class="card-filter-section">
          <b-row>
            <b-col
              cols="8"
              lg="9"
              class="d-block d-lg-flex align-items-center justify-content-start flex-wrap"
            >
              <p class="filter-title">
                Filters:
              </p>
              <div class="">
                <custom-dropdown
                  v-model="is_active"
                  :options="isActiveList"
                  :search-box="false"
                  :hide-close="true"
                  label="Active"
                  icon="EditIcon"
                />
              </div>
              <div
                v-if="userInfo.role == 'SA'"
                class=""
              >
                <custom-dropdown
                  v-model="manage_by"
                  :options="manage_by_users"
                  label="Managed by"
                  icon="EditIcon"
                />
              </div>

              <div
                v-if="userInfo.role == 'SA'"
                class=""
              >
                <custom-dropdown
                  v-model="reporting_to"
                  :options="reporting_to_users"
                  label="Reporting to"
                  icon="EditIcon"
                />
              </div>
              <div class="w-lg-100">
                <custom-dropdown
                  v-model="searchUser"
                  :options="filterUserOptions"
                  label="Members"
                  multiple
                  icon="EditIcon"
                />
              </div>
              <div
                v-if="userInfo.role == 'SA'"
                class=""
              >
                <custom-dropdown
                  v-model="group_id"
                  :options="groups"
                  label="Group"
                  icon="EditIcon"
                />
              </div>
              <OptionDropdown v-model="per_page" />
            </b-col>
            <b-col
              cols="4"
              lg="3"
              style="text-align: end"
            >
              <button
                class="apply-filter filterRedesign mr-1"
                @click="ApplyFilter()"
              >
                Apply Filter
              </button>
              <button
                class="apply-filter-clear filterRedesign"
                @click="clearFilter()"
              >
                Clear
              </button>
            </b-col>
          </b-row>
        </div>
      </div>
      <div v-if="tab == 'daily'">
        <view-edit-body
          ref="dailyLog"
          :start-date="startDate"
          :end-date="endDate"
          :user-i-d="user_id"
          :manage-by="manage_by"
          :reporting-to="reporting_to"
          :group-id="group_id"
          :per-page="per_page"
          :is-active="is_active"
        />
      </div>
      <!-- weekly timesheet data -->
      <Weekly
        v-if="tab == 'weekly'"
        ref="weeklyLog"
        :start-date="startDate"
        :end-date="endDate"
        :user-i-d="user_id"
        :per-page="per_page"
        :manage-by="manage_by"
        :group-id="group_id"
        :reporting-to="reporting_to"
        :is-active="is_active"
      />
    </div>
  </div>
</template>

<script>
import {
  VBToggle,
} from 'bootstrap-vue'
import { required } from '@validations'
import * as moment from 'moment/moment'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import { eventBus } from '@/main'
import Help from '@/components/HelpDesk/Help.vue'
import Weekly from './Weekly.vue'
import OptionDropdown from '../../components/optiondropdown/OptionDropdown.vue'
import ViewEditBody from './ViewEditbody.vue'

export default {
  name: 'ViewEditHeader',
  components: {
    ViewEditBody,
    Weekly,
    customDropdown,
    OptionDropdown,
    Help,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      show: false,
      per_page: 25,
      tab: null,
      selected: null,
      option: [{ title: 'Square' }, { title: 'Square' }],
      multiDate: null,
      required,
      hideHeader: true,
      hideFooter: true,
      daily: true,
      weekly: false,
      calendar: false,
      startDate: this.$route.query.startDate || moment().startOf(this.tab === 'daily' ? 'day' : 'week').format('YYYY-MM-DD'),
      endDate: this.$route.query.endDate || moment().startOf(this.tab === 'daily' ? 'day' : 'week').format('YYYY-MM-DD'),
      users: [],
      user_id: [],
      weekDate: null,
      is_active: 'active',
      dateDayRangeConfig: {
        inline: true,
        dateFormat: 'Y-m-d',
        mode: 'single',
        maxDate: new Date(),
      },
      dateRangeConfig: {
        mode: 'range',
        inline: true,
        maxDate: moment().endOf('week').add(1, 'd').format('YYYY-MM-DD'),
      },
      searchUser: [],
      manage_by: null,
      reporting_to: null,
      reporting_to_users: [],
      manage_by_users: [],
      group_id: null,
      groups: [],
      activeBtn: (this.$route.query && this.$route.query.active) || null,
    }
  },
  computed: {

    filterUserOptions() {
      const users = []
      this.users.forEach(element => {
        users.push({
          value: element.id,
          label: `${element.name} ${element.last_name}`,
        })
      })
      return users
    },

  },
  watch: {
    startDate() {
      this.queryDataUpdate()
    },

    manage_by() {
      this.searchUser = []
      this.userList()
    },

    reporting_to() {
      this.userList()
    },

    tab(nv, old) {
      if (nv) {
        if (nv === 'daily') {
          this.startDate = moment().startOf('day').format('YYYY-MM-DD')
          this.endDate = moment().endOf('day').format('YYYY-MM-DD')
        } else if (old === 'daily') {
          this.startDate = moment().startOf('week').add(1, 'days').format('YYYY-MM-DD')
          this.endDate = moment().endOf('week').add(1, 'days').format('YYYY-MM-DD')
        }
        this.queryDataUpdate()
      }
    },

  },

  mounted() {
    this.userList()
    if (this.$route.query) {
      const { query } = this.$route
      this.tab = query.tab || 'daily'
      this.startDate = query.startDate
      this.endDate = query.endDate
      this.per_page = query.perPage ? query.perPage : 25
      this.is_active = query.is_active ? query.is_active : this.is_active
      this.manage_by = query.manage_by || this.manage_by
      this.reporting_to = query.reporting_to || this.reporting_to
      this.group_id = query.group_id
      this.searchUser = query.userID ? (typeof query.userID === 'string' ? this.searchUser[query.userID] : query.userID) : []
      this.user_id = query.userID ? (typeof query.userID === 'string' ? [query.userID] : query.userID) : []
      this.activeBtn = query.active || this.activeBtn
    }
    this.tab = this.tab || 'daily'

    if (this.tab === 'daily') {
      this.startDate = moment().startOf('day').format('YYYY-MM-DD')
      this.endDate = moment().endOf('day').format('YYYY-MM-DD')
    }

    this.filterFunctionCallFromMixin()
  },
  methods: {

    updateDate(obj) {
      this.startDate = obj && obj.startDate ? obj.startDate : null
      this.endDate = obj && obj.endDate ? obj.endDate : null
      this.activeBtn = obj && obj.active ? obj.active : null
      this.apiCall()
    },

    queryDataUpdate() {
      const query = {
        is_active: this.is_active,
        manage_by: this.manage_by,
        reporting_to: this.reporting_to,
        userID: this.searchUser,
        group_id: this.group_id,
        startDate: this.startDate,
        endDate: this.endDate,
        perPage: this.per_page,
        tab: this.tab,
        pageNumber: this.$route.query.pageNumber,
        active: this.activeBtn,
      }
      this.routeQueryUpdate(query)
    },

    /*  filter data */
    async filterFunctionCallFromMixin() {
      if (this.userInfo && this.userInfo.role === 'SA') {
        if (this.$store.state.app.groupList.length === 0) {
          this.groups = await this.getGroupData()
        } else {
          this.groups = this.$store.state.app.groupList
        }
      }

      if (this.$store.state.app.manageByUsersList.length === 0) {
        this.manage_by_users = await this.manageByUserList()
      } else {
        this.manage_by_users = this.$store.state.app.manageByUsersList
      }

      if (this.$store.state.app.reportingUserList.length === 0) {
        this.reporting_to_users = await this.reportingtoUserList()
      } else {
        this.reporting_to_users = this.$store.state.app.reportingUserList
      }
    },

    apiCall() {
      setTimeout(() => {
        if (this.tab === 'weekly') {
          eventBus.$emit('clockWeeklyTimeSheet', true)
        } else {
          eventBus.$emit('clockDailyTimeSheet', true)
        }
      }, 200)
    },

    ApplyFilter() {
      this.user_id = this.searchUser
      this.manage_by = this.manage_by ? this.manage_by : null
      this.reporting_to = this.reporting_to ? this.reporting_to : null
      this.apiCall()
      this.queryDataUpdate()
    },

    clearFilter() {
      this.manage_by = null
      this.reporting_to = null
      this.searchUser = []
      this.user_id = []
      this.group_id = null
      this.per_page = 25
      this.is_active = 'active'
      this.apiCall()
      this.queryDataUpdate()
    },

    /* user List */
    async userList() {
      this.userIds = []
      const input = {
        manage_by: this.manage_by ? this.manage_by : null,
        reporting_to: this.reporting_to ? this.reporting_to : null,
        sort_field: 'name',
        sort_order: 'asc',
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.users = data.users
      }
    },
  },
}
</script>

<style lang="scss">
@import "../../assets/scss/component-css/vieweditheader.scss";
@import "../../assets/scss/component-css/viewedit.css";
</style>
