var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"user-table table"},[(_vm.loader)?_c('TableLoader',{attrs:{"fields":_vm.fields}}):_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-table',{staticClass:"scrollable-element user-table-item time-sheet-table tableDesigns",attrs:{"id":"table","responsive":"","items":_vm.items,"fields":_vm.fields,"show-empty":""},scopedSlots:_vm._u([{key:"cell(full_name)",fn:function(data){return [_c('div',{staticClass:"media align-items-center"},[_c('div',{staticClass:"media-aside align-items-center"},[_c('b-avatar',{staticClass:"badgeDesign",attrs:{"size":"md","src":data.item.picture,"text":_vm._f("avatarText")(data.item.full_name),"badge":"","badge-variant":_vm.userStatus(data.item.clock),"variant":_vm.randomVariant(1, _vm.items.length),"badge-offset":"-2px"}})],1),_c('div',{staticClass:"media-body"},[_c('p',{staticClass:"d-block user-emp-name tabletxtColor",style:({ color: '#' + data.item.color })},[_vm._v(" "+_vm._s(data.item.name)+" "+_vm._s(data.item.last_name)+" ")])])])]}},{key:"cell(duration)",fn:function(data){return [_vm._l((6),function(n){return _c('span',{key:n,staticClass:"progressbar-fix-parameter",style:(n == 1
                  ? 'width: 16.40%;'
                  : n == 6
                    ? 'width: 0%;'
                    : 'width: 16.40%;')},[_vm._v(" "+_vm._s(n == 1 ? "12AM" : n == 4 ? "12PM" : n < 4 ? (n - 1) * 4 + "AM" : (n - 4) * 4 + "PM"))])}),(data.item.clock && data.item.clock.length)?_c('b-progress',{staticClass:"float-left w-100",attrs:{"max":_vm.max}},_vm._l((data.item.clock),function(p,index){return _c('b-progress-bar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top.html",value:(
                  _vm.prepareToolTipValue(p)
                ),expression:"\n                  prepareToolTipValue(p)\n                ",modifiers:{"hover":true,"top":true,"html":true}}],key:index,staticClass:"custom-progress",style:({
                  'background-color':
                    '#' +
                    (p && p.project && p.project.color
                      ? p.project.color
                      : '6bbe74'),
                }),attrs:{"value":(p.time_diff / 86400) * 100,"show-progress":""}})}),1):_c('b-progress',{staticClass:"float-left w-100",attrs:{"max":_vm.max}},[_c('b-progress-bar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover.top",value:('Not worked'),expression:"'Not worked'",modifiers:{"hover":true,"top":true}}],style:({ 'background-color': '#d3d3d3' }),attrs:{"value":"100"}})],1)]}},{key:"cell(time)",fn:function(data){return [_c('p',{staticClass:"tabletxtColor"},[_vm._v(" "+_vm._s(_vm.totalDailyTime(data.item.clock))+" ")])]}}])}),(_vm.totalCount == 0 && !_vm.loader)?_c('img',{staticClass:"no-data-img",attrs:{"src":require("@/assets/images/nodatafound/nodata.svg"),"alt":"no-data"}}):_vm._e()],1),_c('b-col',{attrs:{"cols":"12"}},[(_vm.totalCount>0)?_c('Pagination',{attrs:{"current-page":_vm.currentPage,"per-page":_vm.perPage,"total-count":_vm.totalCount},on:{"currentPage":function($event){_vm.currentPage = $event}}}):_vm._e()],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }