<template>
  <div class="weekly-mainsection weekly-padding">
    <div class="weekly-timesheet mb-1" />
    <time-sheet-loader v-if="loader" :row-num="2" />
    <div v-else-if="users.length" class="weekly-timesheet-calendar">
      <table>
        <tr>
          <th class="table-title">
            Weekly Timesheet
          </th>
          <th v-for="(date, dateKey) in weekDates" :key="dateKey">
            <div class="table-head">
              <span>{{ date | getDayOnly }}</span>
              <div>
                <p>{{ date | getDayNameOnly }}</p>
                <p class="month">
                  {{ date | getMonthOnly }}
                </p>
              </div>
            </div>
          </th>
          <th class="table-title">
            Total
          </th>
        </tr>
        <tr v-for="(user, index) in users" :key="index">
          <td>
            <div class="projects-list">
              <b-avatar variant="primary" size="50" :text="user.full_name | avatarText"
                :src="user && user.picture ? user.picture : ''" :style="{ 'background-color': '#11111' }"
                class="avtar" />
              <div class="project-data">
                <p class="project-name">
                  {{ user.full_name }}
                </p>
              </div>
            </div>
          </td>

          <td v-for="(date, dateKey) in weekDates" :key="dateKey">
            <b-button :id="index + 'check' + dateKey" class="tootl-tip"
              :class="user.weekData[dateKey] && user.weekData[dateKey].First_in ? 'cursor-pointer' : 'cursorDefault'"
              @click="
      user.weekData[dateKey] &&
        (user.weekData[dateKey].total ||
          user.weekData[dateKey].First_in)
        ? redirectToModal(user, date)
        : ''
      ">
              {{
      user.weekData[dateKey] && user.weekData[dateKey].total
        ? secondsToTime(user.weekData[dateKey].total)
        : user.weekData[dateKey].First_in
          ? "in at " + (user.weekData[dateKey].First_in ? (user.weekData[dateKey].First_in).substring(0, 5) : '')
          : "--"
    }}
            </b-button>
            <b-tooltip :target="index + 'check' + dateKey" triggers="hover">
              <p v-if="user.weekData[dateKey].First_in" class="mb-0">
                First in : {{ user.weekData[dateKey].First_in ? (user.weekData[dateKey].First_in).substring(0, 5) : ''
                }}
              </p>
              <p v-if="user.weekData[dateKey].Last_out" class="mb-0">
                Last out : {{ user.weekData[dateKey].Last_out ? user.weekData[dateKey].Last_out.substring(0, 5) : '' }}
              </p>
            </b-tooltip>
          </td>

          <td class="total-timerecoard">
            {{ totalTime(user.weekData) }}
          </td>
        </tr>
      </table>

    </div>

    <div v-else class="w-100">
      <img src="@/assets/images/nodatafound/nodata.svg" alt="no-data" class="no-data-img">
    </div>
    <!-- pagination section -->
    <Pagination v-if="totalCount > 0" :current-page="currentPage" :per-page="perPage" :total-count="totalCount"
      @currentPage="currentPage = $event" />
    <!-- pagination section complete here -->

  </div>
</template>

<script>
import { BTooltip, BButton } from 'bootstrap-vue'
import { eventBus } from '@/main'
import TimeSheetLoader from '@/components/loaders/timesheet-loader.vue'
import CryptoJS from 'crypto-js'
import Pagination from '../../components/pagination/index.vue'

export default {
  name: 'Weekly',
  components: {
    BTooltip,
    TimeSheetLoader,
    BButton,
    Pagination,
  },
  props: {
    startDate: {
      type: String,
      required: false,
      default: null,
    },
    endDate: {
      type: String,
      required: false,
      default: null,
    },
    userID: {
      type: Array,
      required: false,
      default: () => ([]),
    },
    perPage: {
      type: [Number, String],
      required: false,
      default: 10,
    },
    manageBy: {
      type: String,
      required: false,
      default: null,
    },
    reportingTo: {
      type: String,
      required: false,
      default: null,
    },
    groupId: {
      type: String,
      required: false,
      default: null,
    },
    isActive: {
      type: String,
      required: false,
      default: 'active',
    },
  },
  data() {
    return {
      loader: true,
      totalCount: 0,
      currentPage: this.$route.query.pageNumber || 1,
      weekDates: [],
      weekData: [],
      users: [],
    }
  },
  watch: {

    currentPage: {
      handler(value) {
        if (value) {
          this.timeSheetData()
        }
      },
    },

    startDate(nv) {
      if (nv) {
        this.weekDates = this.enumerateDaysBetweenDates(this.startDate, this.endDate)
        this.timeSheetData()
      }
    },

    isActive(nv) {
      if (nv) {
        this.weekDates = this.enumerateDaysBetweenDates(this.startDate, this.endDate)
        this.timeSheetData()
      }
    },

    perPage() {
      if (this.currentPage === 1) {
        this.timeSheetData()
      } else {
        this.currentPage = 1
      }
    },
  },

  mounted() {
    /* set date range */
    if (this.startDate && this.endDate) {
      this.weekDates = this.enumerateDaysBetweenDates(this.startDate, this.endDate)
      this.timeSheetData()
    }
    /* Handle event */
    eventBus.$on('logListReLoad', data => {
      this.eventBusCommonLogic(data)
    })
    eventBus.$on('clockWeeklyTimeSheet', data => {
      this.weekDates = this.enumerateDaysBetweenDates(this.startDate, this.endDate)
      this.eventBusCommonLogic(data)
    })
    eventBus.$on('hr-time-sheet-update', data => {
      this.eventBusCommonLogic(data)
    })
  },
  destroyed() {
    eventBus.$off('logListReLoad')
    eventBus.$off('clockWeeklyTimeSheet')
    eventBus.$off('hr-time-sheet-update')
  },
  methods: {
    eventBusCommonLogic(data) {
      if (data) {
        /* to avoid multiple api call */
        if (this.currentPage === 1) {
          this.timeSheetData()
        } else {
          this.currentPage = 1
        }
      }
    },
    redirectToModal(user, date) {
      const hrTab = CryptoJS.AES.encrypt(
        'Weekly',
        this.VUE_APP_SECRET_KEY,
      ).toString()
      localStorage.setItem('hrTab', hrTab)
      this.$router.push({
        name: 'userLog',
        query: {
          user_id: user.id,
          date,
          is_active: this.isActive,
          manage_by: this.manageBy,
          reporting_to: this.reportingTo,
          userID: this.userID,
          group_id: this.groupId,
          startDate: this.startDate,
          endDate: this.endDate,
          pageNumber: this.currentPage,
          perPage: this.perPage,
          tab: 'weekly',
          active: (this.$route.query && this.$route.query.active) || null,
        },
      })
    },
    totalTime(data) {
      let total = 0
      if (data && data.length) {
        data.forEach(element => {
          total += element.total
        })
      }
      return this.secondsToTime(total)
    },

    /**
     * Get daily timesheet data
     */
    async timeSheetData() {
      this.loader = true
      if (!this.startDate || !this.endDate) {
        return
      }
      const input = {
        is_active:
          this.isActive && this.isActive !== 'all_data' ? this.isActive === 'active' : '',
        start_date: this.startDate,
        end_date: this.endDate,
        page: this.currentPage,
        per_page: this.perPage,
        sort_field: 'name',
        sort_order: this.sortDesc ? 'desc' : 'asc',
        manage_by: this.manageBy,
        reporting_to: this.reportingTo,
        member: this.userID,
        group_id: this.groupId,
        tab: 'weekly',
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/timesheet/dailyTimeSheet',
        input,
        false,
      )
      if (response && response.status === 200) {
        const { data } = response

        // Set log data and total count
        this.totalCount = data.count

        // Process each user's week data
        if (data.users && data.users.length) {
          data.users.forEach(user => {
            user.weekData = []

            this.weekDates.forEach(date => {
              let total = 0
              const InOutToolTip = []

              // Filter clocks for the current date
              if (user.clock && user.clock.length) {
                user.clock.forEach(clock => {
                  if (clock.in_date === date) {
                    InOutToolTip.push(clock)
                    total += clock.time_diff
                  }
                })
              }

              // Sort InOutToolTip by in_time
              InOutToolTip.sort((a, b) => (this.totalMinutes(a.in_time) > this.totalMinutes(b.in_time) ? 1 : -1))

              // Prepare data object for the current date
              const data = {
                total,
                First_in: InOutToolTip.length ? InOutToolTip[0].in_time : '',
                Last_out: InOutToolTip.length ? (InOutToolTip[InOutToolTip.length - 1].out_time ? InOutToolTip[InOutToolTip.length - 1].out_time : (InOutToolTip.length > 1 ? InOutToolTip[InOutToolTip.length - 2].out_time : InOutToolTip[0].out_time)) : '',
              }

              // Add the data object to weekData array
              user.weekData.push(data)
            })

            // Set users array after processing week data
            this.users = data.users

            /* set in route */
            const { query } = this.$route

            const obj = {
              is_active: query.is_active,
              manage_by: query.manage_by,
              reporting_to: query.reporting_to,
              userID: query.searchUser,
              group_id: query.group_id,
              startDate: query.startDate,
              endDate: query.endDate,
              perPage: query.per_page,
              tab: query.tab,
              pageNumber: this.currentPage || 1,
              active: (query && query.active) || null,
            }
            this.routeQueryUpdate(obj)
          })
        } else {
          this.users = []
        }
        this.loader = false
      } else {
        this.loader = false
      }
    },

    /* time difference */
    totalMinutes(time) {
      const hours = time.substring(0, 2) * 60
      const minutes = time.substring(3, 5)
      return (Number(hours) + Number(minutes)) * 60
    },

  },
}
</script>

<style lang="scss">
@import "../../assets/scss/component-css/viewbody.css";
@import "../../assets/scss/variables/_variables.scss";

.weekly-mainsection .weekly-timesheet-calendar table th:last-child {
  color: #5e5873;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.tootl-tip {
  background-color: transparent !important;
  color: #5e5873 !important;
  border: none !important;
  box-shadow: none !important;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
}

.weekly-padding {
  padding: 20px 20px 60px
}
</style>
